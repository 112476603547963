const PlaceHelper = () => {
    
    const placesData = {
        /*"placeholder" : {
            id: "xyz",
            fullPlaceName: "Loading...",
            appStoreLink: "/",
            banner: "/img/banner-placeholder.jpg",
            details: [
                {
                    image: "/img/banner-placeholder.jpg",
                    title: "...",
                    description: "..."
                },{
                    image: "/img/banner-placeholder.jpg",
                    title: "...",
                    description: "..."
                },{
                    image: "/img/banner-placeholder.jpg",
                    title: "...",
                    description: "..."
                }
            ]
        },*/
        "new-york" : {
            id: "nyc",
            fullPlaceName: "New York City",
            appStoreLink: "https://apps.apple.com/us/app/360-nyc-new-york-city-ar-map/id1260422375",
            banner: "https://360centralpark.vunet.us/data/skin/360nyc/img/intro-bg.jpg",
            details: [
                {
                    image: "https://360nyc.vunet.us/data/skin/360nyc/img/iphone-HOME.png",
                    title: "Experience New York City",
                    description: "in a new futuristic way by looking into your phone's camera, instead of the map, to find the most popular places of interest all around you. 360 NYC is an Augmented Reality (AR) app that displays places you want to find anywhere you point the phone."
                },{
                    image: "https://360nyc.vunet.us/data/skin/360nyc/img/iphone-DETAILS.png",
                    title: "Hundreds of places",
                    description: "in multiple categories are available including Empire State Building, Statue of Liberty, Times Square, landmarks, historic sites, museums, parks and many others. Special packages include all places of Central Park and Hidden Gems of New York City. The database of custom selected places is growing continuously for your best experience in Manhattan, Brooklyn, Queens, Bronx and Staten Island."
                },{
                    image: "https://360nyc.vunet.us/data/skin/360nyc/img/iphone-SEARCH.png",
                    title: "360 NYC app",
                    description: "is a panoramic viewer with AR camera that stacks the places of interest within the specified distance. Tapping the place expands additional details for easier navigation, including the map view, directions, contacts, address information and description."
                }
            ]
        },
        "central-park-nyc": {
            id: "cp",
            fullPlaceName: "Central Park",
            appStoreLink: "https://apps.apple.com/us/app/360-central-park-nyc-ar-map/id1521191081",
            banner: "https://360centralpark.vunet.us/data/skin/360nyc/img/intro-bg.jpg",
            details: [
                {
                    image: "https://360centralpark.vunet.us/data/skin/360centralpark/img/iphone-HOME.png",
                    title: "Experience Central Park of New York City",
                    description: "in a new futuristic way by looking into your phone's camera, instead of the map, to find the most popular places of interest all around you. 360 Central Park is an Augmented Reality (AR) app that displays places you want to find anywhere you point the phone."
                },{
                    image: "https://360centralpark.vunet.us/data/skin/360centralpark/img/iphone-ROUTE.png",
                    title: "The most visited places",
                    description: "in multiple categories are available including Strawberry Fields, Belvedere Castle, Central Park Zoo, Carousel, fountains, gardens, hidden gems and many others."
                },{
                    image: "https://360centralpark.vunet.us/data/skin/360centralpark/img/iphone-HOME3.png",
                    title: "360 Central Park app",
                    description: "is a panoramic viewer with AR camera that stacks the places of interest within the specified distance. Tapping the place expands additional details for easier navigation, including the map view, directions, contacts, address information and description."
                }
            ]
        },
        "los-angeles": {
            id: "la",
            fullPlaceName: "Los Angeles",
            appStoreLink: "https://apps.apple.com/us/app/360-la-los-angeles-ar-map/id1587351081",
            banner: "https://360la.vunet.us/data/skin/360la/img/intro-bg.jpg",
            details: [
                {
                    image: "https://360la.vunet.us/data/skin/360la/img/iphone-HOME.png",
                    title: "Experience Los Angeles",
                    description: "in a new futuristic way by looking into your phone's camera, instead of the map, to find the most popular places of interest all around you. 360 LA is an Augmented Reality (AR) app that displays places you want to find anywhere you point the phone."
                },{
                    image: "https://360la.vunet.us/data/skin/360la/img/iphone-detail.png",
                    title: "The most visited places",
                    description: "in multiple categories are available including Universal Studios Hollywood, Griffith Park and Observatory, Hollywood, Santa Monica, and various museums including the Getty Center and more."
                },{
                    image: "https://360la.vunet.us/data/skin/360la/img/iphone-search.png",
                    title: "360 LA app",
                    description: "is a panoramic viewer with AR camera that stacks the places of interest within the specified distance. Tapping the place expands additional details for easier navigation, including the map view, directions, contacts, address information and description."
                }
            ]
        }
    }

    const getCurrentPlaceName = () => {

      const allPlaces = [
       /* "placeholder",*/ "new-york", "central-park-nyc", "los-angeles"
      ];
      
      let placeName = allPlaces[0]; // set default
      
      // SSR uses default
      if (typeof window === "undefined") 
            return placeName;
    
     // get place from URL and override saved or default
     if (window.location.href.indexOf("place=") !== -1){
        allPlaces.forEach( place => {
            if( window.location.href.indexOf("place=" + place) !== -1){
              placeName = place; // override default
              // save place for future
              window.localStorage.setItem("place", placeName);
              return placeName;
            }
        });
      }

      // Read from local storage and override default
      const savedPlace = window.localStorage.getItem("place");
      if (typeof savedPlace === "string"){
        allPlaces.forEach( place => {
          if( savedPlace === place){
            placeName = savedPlace; // override default
            return placeName;
          }
        });
      }

      return placeName;
    }

    const getCurrentPlaceData = () => {
        return placesData[ getCurrentPlaceName() ];
    }

    return {
        getCurrentPlaceData
    }

}

export default PlaceHelper