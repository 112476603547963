/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AppContext from "./appcontext"
import PlaceHelper from "./placehelper"

//import Header from "./header"
import "./layout.css"

import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

const placeHeloper = PlaceHelper();
const currentPlace = placeHeloper.getCurrentPlaceData();
console.log("AppContext Layout: currentPlace: ", currentPlace);
let [placeData, setPlaceData] = useState({...currentPlace })
const placeValue = { placeData, setPlaceData }

  // Old code removed from JSX
  // <Header siteTitle={data.site.siteMetadata.title} /> 
  /*
<div
        style={{
          /*margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,* /
        }}
      >
  */
  return (
    <>
    <AppContext.Provider value={placeValue}>
      <main>{children}</main>
    </AppContext.Provider>
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
